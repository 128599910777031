define(['jquery', 'productDetails', 'productDetailsTpl', 'siteObj', '$console'], function($, ProductDetails, productDetailsTpl, siteObj, $console) {
  
  if (siteObj.features.product.allvariations) {
    $(function() {
      $.getJSON(siteObj.siteURL + 'allvariations.json?productId=' + siteObj.productID)
        .done(function(data) {
          var productDetails = new ProductDetails({
            details: data
          });

          // find the server side variations panel and
          // insert the client side element before it
          var $existing = $('.product-variation').eq(0);
          var $variations = $('<div>').insertBefore($existing);
          var ui = new SimpleVariationsUI($variations, productDetails);
          // onchange quantity, update the ProductDetails model
          ui.$el.on('change', 'input[name=quantity]', function() {
            var quantity = parseInt($(this).val());
            if (!isNaN(quantity)) {
              productDetails.quantity(quantity);
            }
          });

          // onchange any select element, update/clear the ProductDetails selections
          ui.$el.on('change', 'select', function() {
            var $this = $(this);
            var childProductId = $this.val();
            if (childProductId) {
              var childProduct = productDetails.details().children[childProductId];
              productDetails.select(childProduct.options);
            } else {
              productDetails.clearSelection();
            }
          });

          // onsubmit form, call the common basket.init_add function
          ui.$el.on('submit', 'form', function(e) {
            e.preventDefault();

            var $form = $(this);
            var productId = productDetails.details().product.id;
            var quantity = productDetails.quantity();
            var $button = $form.find('button[type=submit]').eq(0);
            var variations = productDetails.getSelectedVariationsPostData();

            siteObj.fn.basket.init_add(productId, quantity, $button, variations);

            return false;
          });

          // attach model event handlers
          productDetails.on(ProductDetails.prototype.events.SELECTION_CHANGED, function() {
            ui.render(this);
          });

          // remove old server generated HTML
          $variations.siblings('.product-variation, .bulk-discounts, .product-buying-area').remove();

          // render ui
          ui.render(productDetails);

        })
        .fail(function(data, textStatus) {
          $console.log('Error retrieving product variations data', textStatus);
        });
    });

  }

  /*
   * A simple html view representing the data on the Product Details page.
   * @constructor
   */
  function SimpleVariationsUI($el) {
    this.$el = $el;
  }
  SimpleVariationsUI.prototype = {
    render: function(productDetails) {

      var details = productDetails.details();
      var selectedChildProductId = productDetails.selectedChildProductId() || -1;

      // shape the view data
      var viewData = {
        produtid: details.product.id,
        title: details.product.title,
        price: productDetails.price(),
        quantity: productDetails.quantity(),
        canAddToBasket: productDetails.canAddToBasket(),
        labelText: 'Options',
        options: []
      };

      // build all options list
      for (var childId in details.children) {
        var child = details.children[childId];
        var texts = [];
        for (var optionId in child.options) {
          var optionValue = child.options[optionId];
          texts.push(details.variations[optionId].options[optionValue].name);
        }
        var text = texts.join(' | ');
        viewData.options.push({
          text: text,
          title: text,
          value: childId,
          selected: (childId === selectedChildProductId)
        });
      }

      // sort options alphabetically
      viewData.options.sort(function(a, b) {
        if (a.text < b.text) {
          return -1;
        } else if (a.text > b.text) {
          return 1;
        } else {
          return 0;
        }
      });

      viewData.options.unshift({
        text: details.texts.noSelectionText,
        selected: (selectedChildProductId === -1)
      });

      // render template
      this.$el.html(productDetailsTpl(viewData));
    }
  };

});
